import $ from "jquery";
import { Network } from "./utils/Network";
import { Toast } from "./utils/Toast";
import { formToJSON } from "./utils/form";
import { wait } from "./utils/f";
import { toDate } from "./utils/datetime";
import { styleCurrentAnchors } from "./utils/anchors";
import { route } from "./routes.js";

window.jQuery = window.$ = $;

/*/ preloader /*/
window.addEventListener("DOMContentLoaded", function(event)
{
    $('.preloader').delay(500).fadeOut(500);
});

/*/ navicon /*/
const navicon = document.querySelector(".navicon");
if(navicon)
{
    navicon.addEventListener("click", function(event){
        let menuOpen = false;

        const menus = document.querySelectorAll(".segment.top-nav ul");
        for (const i of menus)
        {
            menuOpen = i.classList.contains("hidden");
            i.classList.toggle("hidden");
        }

        const naviconChildren = navicon.querySelectorAll("i");
        for (const i of naviconChildren)
        {
            i.classList.toggle("hidden");
        }

        if(!menuOpen)
        {
            document.body.style.overflow = "auto";
            return;
        }

        document.body.style.overflow = "hidden";
    });
}

/*/ Side nav toogle /*/
const sideNavToggle = document.querySelector("[data-action='toggle-sidenav']");
if(sideNavToggle)
{
    sideNavToggle.addEventListener("click", function(event)
    {
        const naviconChildren = sideNavToggle.querySelectorAll("i");
        for (const i of naviconChildren)
        {
            i.classList.toggle("hidden");
        }

        const sideNav = document.querySelector(".side-nav");
        if(sideNav)
        {
            sideNav.classList.toggle("hidden");
        }
    });
}

/*/ Logout /*/
const actionLogout = document.querySelector("a[data-action='logout']");
if(actionLogout)
{
    actionLogout.addEventListener("click", logout);
}

function logout()
{
    Toast.confirm("You will be logged out if you choose to proceed!")
    .then(() => {
        Network.post(new URL(route("logout")))
        .finally(() => window.location = new URL(route("home.index")));
    })
    .catch((error) => {});
}

/*/ Contact Form /*/
if(typeof contactForm !== 'undefined')
{
    submitContactForm.addEventListener("click", contact);
}

function contact(event)
{
    if(! contactForm.reportValidity())
    {
        return;
    }

    const dataObj = formToJSON(contactForm);

    submitContactForm.classList.toggle("loading");
    submitContactForm.classList.toggle("disabled");
    Toast.make("Please wait. Your query is being recorded.", Toast.info, 4);

    Network.post(new URL(route("contactus.store")), dataObj)
        .then(function(response){
            Toast.make(response.message, Toast.success)
            .then(() => { contactForm.reset(); });
        })
        .catch(function(error){
            Toast.make(error, Toast.error, Toast.DELAY_INFINITY);
        })
        .finally(function(){
            submitContactForm.classList.toggle("loading");
            submitContactForm.classList.toggle("disabled");
        });
}

/*/ https://stackoverflow.com/a/688199/10633355 /*/
window.addEventListener("load", function(event){
    // code
});

document.addEventListener("DOMContentLoaded", function(event){
    displayGlobalNotifications();
    styleCurrentAnchors();
})

if(typeof registrationForm !== 'undefined')
{
    registrationForm.addEventListener("submit", register);
}

if(typeof loginForm !== 'undefined')
{
    loginForm.addEventListener("submit", login);
}

function login(event)
{
    const ok = loginForm.reportValidity();
    if(!ok)
    {
        return;
    }

    const data = formToJSON(loginForm);

    let submits = loginForm.querySelectorAll("[type='submit'], a.button-link");
    for (const i of submits)
    {
        if(i.tagName.toLowerCase() === "a")
        {
            i.classList.toggle("disabled");
            i.classList.toggle("loading");
        }
        else
        {
            i.setAttribute('disabled', true);
        }
    }

    Network.post(new URL(route("login.store")), data)
        .then(function(response){
            if(response.redirectTo)
            {
                window.location.href = response.redirectTo;
            }
            else
            {
                Toast.make(response.message, Toast.success, 7);
                wait(1, function()
                {
                    window.location.href = new URL(route("home.index"));
                });
            }
        })
        .catch(function(e){
            Toast.make(e.message, Toast.error, Toast.DELAY_INFINITY);
        })
        .finally(function(){
            for (const i of submits)
            {
                if(i.tagName.toLowerCase() === "a")
                {
                    i.classList.toggle("disabled");
                    i.classList.toggle("loading");
                }
                else
                {
                    i.removeAttribute('disabled');
                }
            }
        });
}

function register(event)
{
    const ok = registrationForm.reportValidity();
    if(!ok)
    {
        return;
    }

    const data = formToJSON(registrationForm);

    if(data.password != data.password_confirmation)
    {
        Toast.make("Passwords do not match!", Toast.error, Toast.DELAY_INFINITY);
        return;
    }

    data.terms_of_service_accepted = 1;
    data.timezone_name = "UTC";
    let name = data.name.split(" ");
    if(name.length === 1)
    {
        data.first_name = data.name.trim();
    }
    else
    {
        data.first_name = name.shift();

        if(name.length == 1)
        {
            data.last_name = name.shift();
        }
        else
        {
            data.middle_name = name.shift();
            data.last_name = name.join(" ");
        }
    }

    delete data.name;

    let submits = registrationForm.querySelectorAll("[type='submit'], a.button-link");
    for (const i of submits)
    {
        if(i.tagName.toLowerCase() === "a")
        {
            i.classList.toggle("disabled");
            i.classList.toggle("loading");
        }
        else
        {
            i.setAttribute('disabled', true);
        }
    }

    Network.post(new URL(route("register.store")), data)
        .then(function(response){
            Toast.make(response.message, Toast.success, 7);
            wait(5, function(){
                window.location.href = new URL(route("login.create"));
            });
        })
        .catch(function(e){
            Toast.make(e.message, Toast.error, Toast.DELAY_INFINITY);
        })
        .finally(function(){
            for (const i of submits)
            {
                if(i.tagName.toLowerCase() === "a")
                {
                    i.classList.toggle("disabled");
                    i.classList.toggle("loading");
                }
                else
                {
                    i.removeAttribute('disabled');
                }
            }
        });
}

function displayGlobalNotifications()
{
    if(typeof globalNotificationBox === 'undefined')
    {
        return;
    }

    let notificationInputs = globalNotificationBox.querySelectorAll("[data-notification][data-notification-type]");

    if(notificationInputs.length > 0)
    {
        let messages = [];
        for(let i of notificationInputs)
        {
            if(i.value.trim() != '')
            {
                messages.push({
                    message: `${i.value}`,
                    type: `${i.dataset.notificationType}`
                });
            }

            i.parentNode.removeChild(i);
        }

        if(messages.length > 0)
        {
            messages.forEach(function(item, index){
                if(item.type == 'error')
                {
                    Toast.make(item.message, Toast.error, Toast.DELAY_INFINITY);
                }
                else
                {
                    Toast.make(item.message, Toast.success, 7);
                }
            });

            messages = [];
            globalNotificationBox.innerHTML = '';
        }
    }
}


function loadArticles(page=null)
{
    return;
    if(typeof loadedArticlesContainer === 'undefined')
    {
        return;
    }

    let url = new URL(route("home.posts.latest"));

    if(page)
    {
        page = parseInt(`${page}`);
        url.searchParams.set("page", page);
    }

    Network.get(url)
        .then(function(response){
            const {posts, pagination} = response;

            for (const i of posts)
            {
                loadedArticlesContainer.insertAdjacentHTML("beforeend", `${parseArticle(i)}`);
            }

            if(pagination.current_page !== pagination.last_page)
            {
                loadArticles(parseInt(`${pagination.current_page}`) + 1);
            }
        })
        .catch(function(error){
            console.error(error);
        })
}

function parseArticle(article)
{
    console.log(article);
    const permalink = new URL(route("publicposts.show", [article.slug]))
    let figure, pubDate;

    pubDate = toDate(new Date(article.date_published));

    if(article.featuredVideoUrl)
    {
        figure = `
        <figure>
            <div class="responsive-video">
                <iframe src="${article.featuredVideoUrl}" frameborder="0"></iframe>
            </div>
        </figure>`;
    }
    else if(article.featuredImageUrl)
    {
        figure = `
        <figure>
            <img src="${article.featuredImageUrl}" class="featured-image">
        </figure>`;
    }

    return `
    <article>
        <h3>
            <a href="${permalink}"
                class="link primary">${article.title}</a>
        </h3>
        ${figure ?? ''}
        <p><small>By <strong>${article.author.name}</strong> on <strong>${pubDate}</strong></small></p>
        <p>${article.excerpt} <a href="${permalink}" class="link primary">Read more</a></p>
    </article>`;
}
