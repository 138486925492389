import { loadInOneStylesheet } from "./css";

/**
 * styles current anchors
 */
function styleCurrentAnchors()
{
    const anchors = document.querySelectorAll("a");
    let currentAnchors = [];

    for (const i of anchors)
    {
        if( (i.getAttribute("href") === window.location.href) || (i.getAttribute("href") === '/' && window.location.pathname === '/'))
        {
            i.classList.add("current-anchor");
            currentAnchors.push(i);
        }
    }
}

/**
 * Original JavaScript code by Chirp Internet: www.chirp.com.au
 * https://www.the-art-of-web.com/javascript/remove-anchor-links/
 *
 */
function scrollAnchors()
{
  document.querySelectorAll("a").forEach(function(current)
  {
    if(!current.hash) return;
    if(current.origin + current.pathname != self.location.href) return;
    (function(anchorPoint)
    {
      if(anchorPoint)
      {
        current.addEventListener("click", function(e)
        {
          anchorPoint.scrollIntoView({behavior: "smooth"});
          e.preventDefault();
        }, false);
      }
    })(document.querySelector(current.hash));
  });
}

export {
    styleCurrentAnchors,
    scrollAnchors
};